@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body {
  margin: 0;
  font-family: "Poppins, sans-serif";
  color: "#F9F9F9 !important";
}

// CSS for DataGrid ONCell Click Started
.cursor-pointer {
  cursor: pointer;
}
.ql-tooltip {
  left: 0 !important;
}
.expandable-quill-editor {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.2s;
}
.ql-toolbar.ql-snow {
  background-color: #ffffffde !important;
}
.ql-container {
  border: none;
}

.ql-editor {
  min-height: 300px;
  padding: 10px;
  outline: none;
}

.react-switch-handle {
  background: #000 !important;
}
